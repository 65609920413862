// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  settings: {
    production: false,
    hmr: false,
    updateCheckCycleIntervalMs: 1000 * 5,
     baseUrl: 'http://localhost:5001/api/',
    // baseUrl: 'https://dev.rais.icu/backend/api/',
   //  baseUrl: ' https://stage.rais.icu/backend/api/',
    // baseUrl: 'http://172.25.49.20/backend/api/',
    // baseUrl: ' https://demo.rais.icu/backend/api/',
    // baseUrl: 'http://demo.rais.icu:80/api/',
    // baseUrl: 'https://rais.ronc.ru/api/',
    // baseUrl: 'http://demo.rais.icu:8082/api/',
    // baseUrl: null,
    //driverManagerBaseUrl: null,
    driverManagerBaseUrl: 'http://demo.rais.icu:8080/api/',
    seqBaseUrl: 'https://logs.rais.icu/',
    reportingServerUrl: 'http://212.109.221.38:82',
    enablePwa: false,
    // parametersAggregationMode: 'currentTimeRelative',
    parametersAggregationMode: 'default',
    species: 'human',
    enableHealthCheck: false,
    documentPrintFormat: 'pdf'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
